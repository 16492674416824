import styled from "@emotion/styled";
import { css } from "@emotion/react";

const smButtonHeight = "41px";
const mdButtonHeight = "60px";

export const StyledButton = styled.button`
  width: 141px;
  height: ${smButtonHeight};
  background-color: transparent;

  font-size: 24px;
  line-height: calc(${smButtonHeight} - 3px);

  color: ${(props) => props.theme.colors.white};
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;

  cursor: pointer;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 228px;
    height: ${mdButtonHeight};

    font-size: 36px;
    line-height: calc(${mdButtonHeight} - 3px);
  }
`;

export const Normal = (theme) => css`
  width: 100%;
  height: ${mdButtonHeight};
  max-width: 240px;
  padding: 0 20px;

  color: ${theme.colors.black};
  font-size: 16px !important;
  line-height: normal;
  border-radius: 30px;
  border: 2px solid ${theme.colors.black};

  transition: all 0.1s ease-in;

  &:hover {
    color: ${theme.colors.white};
    background-color: ${theme.colors.black};
  }
`;

export const Outlined = (theme) => css`
  border: 3px solid ${theme.colors.white};
`;
