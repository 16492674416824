const settings = {
  arrows: false,
  pauseOnHover: false,
  infinite: true,
  draggable: false,
  swipe: false,
  speed: 1000,
  ease: "ease-in",
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default settings;
