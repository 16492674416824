import React from "react";
import PropTypes from "prop-types";

import { StyledButton, Normal, Outlined } from "./Button.styles";

const Button = ({ variation, as, children, ...rest }) => {
  const css = variation === "outlined" ? Outlined : Normal;

  return (
    <StyledButton as={as} css={css} {...rest}>
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  variation: PropTypes.oneOf(["normal", "outlined"]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};
Button.defaultProps = {
  variation: "normal",
};

export default Button;
