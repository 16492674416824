import React from "react";
import PropTypes from "prop-types";
import ReactSlick from "react-slick";

import baseSettings from "./settings";

const Carousel = ({ settings, children }) => {
  const mergedSettings = {
    ...baseSettings,
    ...settings,
  };
  return (
    <ReactSlick {...mergedSettings}>
      {children.map((child, index) => (
        <div key={`carousel-slide-${index}`}>{child}</div>
      ))}
    </ReactSlick>
  );
};

Carousel.propTypes = {
  settings: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
Carousel.defaultProps = {};

export default Carousel;
